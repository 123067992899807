<template>
    <div class="boxtitle">
        <div class="box">
            <div class="len"></div>
            <span>{{ title }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "boxtitle",
        props:{
            title:{
                type:String
            }
        }
    }
</script>

<style scoped>
.boxtitle{
    width: 264px;
}
.box{
    width: 264px;
    display: flex;
    align-items: center;
}
.len{
    width: 4px;
    height: 20px;
    background-color: #DD0722;
    margin-right: 6px;
}
</style>
