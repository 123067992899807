<template>
    <div class="home-right">
        <div class="popular">
            <boxtitle title="热门财讯"></boxtitle>
            <div class="lists">
                <div class="item" v-for="(item, index) in popularLists" :key="index" @click="goDetails(index)">
                    <span class="item-num"
                        :class="index === 0 ? 'item-num1' : index === 1 ? 'item-num2' : index === 2 ? 'item-num3' : ''">{{
                            index +
                            1 }}</span>
                    <span class="item-title">{{ item.title }}</span>
                </div>
            </div>
        </div>
        <div class="topic">
            <boxtitle title="话题TOP"></boxtitle>
            <div class="lists">
                <div class="item" v-for="(item, index) in topicLists" :key="index" @click="goHome(item)">
                    <span class="item-dian"></span>
                    <span class="item-topic">#{{ item.name }}</span>
                    <div class="item-add" @click.stop="addCollect(item)" v-if="!item.isCollection">
                        <img src="../../static/icon/add-icon.png">
                        <span>关注</span>
                    </div>
                    <div class="item-add-c" @click="deleteCollect(item)" v-else>
                        <span>取消关注</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-us">
            <boxtitle title="联系我们"></boxtitle>
            <div class="content-item" v-for="(item , index) in rightContentUsInfo" :key="index">
                <img class="icon" :src="item.icon">
                <span v-if="item.value.indexOf('https') === -1">{{ item.value }}</span>
                <img v-else class="value-img" :src="item.value">
            </div>
        </div>
    </div>
</template>

<script>
import boxtitle from '@/components/boxtitle.vue'
import { gethotTopLists, getpopularLists, getInfo } from '@/api/homeRight.js'
import { addCollect, cancelCollect } from '@/api/collect.js'
import { getToken } from "@/utils/auth";
export default {
    name: "homeRight",
    data() {
        return {
            popularLists: [],//热门财讯列表
            topicLists: [],//话题TOP列表
            token: '',
            rightContentUsInfo:[]
        }
    },
    components: {
        boxtitle
    },
    created() {
        this.token = getToken()
        this.getpopularLists()
        this.gethotTopLists()
        this.getInfo()
    },
    methods: {
        /**
         * 获取联系信息
         */
        getInfo() {
            getInfo('ContactWay').then(res=>{
                // this.telephone = res.telephone.value
                // this.wechat = res.wechat.value
                this.rightContentUsInfo = res
            })
        },
        /**
         * 获取热门文章
         */
        getpopularLists() {
            getpopularLists({
                top: 6
            }).then(res => {
                this.popularLists = res
            })
        },
        /**
         * 获取话题TOP列表
         */
        gethotTopLists() {
            gethotTopLists({
                top: 6
            }).then(res => {
                this.topicLists = res
            })
        },
        /**
         * 添加收藏
         */
        addCollect(item) {
            if(!this.token){
                this.$emit('loginOpen')
                return
            }
            addCollect({
                ids: [item.id],
                type: 1
            }).then(() => {
                this.$message.success('关注成功');
                this.gethotTopLists()
            })
        },
        /**
         * 取消关注
         */
        deleteCollect(item) {
            if(!this.token){
                this.$emit('loginOpen')
                return
            }
            cancelCollect({
                id: item.id,
                type: 1
            }).then(() => {
                this.$message.success('取消成功');
                this.gethotTopLists()
            })
        },
        /**
         * 查看详情
         */
        goDetails(index) {
            this.$router.push({ path: "/details", query: { id: this.popularLists[index].id } });
        },
        /**
         * 点击话题
         */
        goHome(item) {
            if (this.$route.path === '/home') {
                this.$emit('addHuati', item)
            } else {
                this.$router.push({ path: '/', query: { huati: item } });
            }
        }
    }
}
</script>

<style scoped>
.popular {
    width: 296px;
    padding: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.popular .lists {
    width: 264px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.popular .lists .item {
    width: 264px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 3px;
    background-color: #F3F4F7;
    margin-top: 8px;
    cursor: pointer;
}

.popular .lists .item .item-num {
    margin-left: 12px;
    margin-right: 12px;
    color: #BBBBBB;
    font-size: 18px;
}

.popular .lists .item .item-num1 {
    color: #E74C5B;
}

.popular .lists .item .item-num2 {
    color: #FFA03B;
}

.popular .lists .item .item-num3 {
    color: #FCBD30;
}

.popular .lists .item .item-title {
    font-size: 16px;
    color: #06121E;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.topic {
    width: 296px;
    padding: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.topic .lists {
    width: 264px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.topic .lists .item {
    width: 264px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
    border-bottom: 1px solid #CDD2D9;
}

.topic .lists .item .item-dian {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #E2450A;
    margin-right: 8px;
}

.topic .lists .item .item-topic {
    flex: 1;
    color: #4181B8;
    font-size: 18px;
    cursor: pointer;
}

.topic .lists .item .item-add {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #E2450A;
    border-radius: 10px;
    width: 50px;
    height: 20px;
}

.topic .lists .item .item-add-c {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #535353;
    border-radius: 10px;
    width: 70px;
    height: 20px;
}

.topic .lists .item .item-add img {
    width: 9px;
    height: 9px;
}

.topic .lists .item .item-add span {
    color: #E2450A;
    font-size: 12px;
    margin-left: 2px;
}

.topic .lists .item .item-add-c span {
    color: #535353;
    font-size: 12px;
    margin-left: 2px;
}

.content-us {
    width: 296px;
    padding: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.content-item {
    width: 264px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;
    cursor: pointer;
}

.content-item .icon {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}

.content-item span {
    font-size: 16px;
    color: #06121E;
}
.content-item .value-img {
    width: 148px;
    height: 148px;
}
</style>
