<template>
    <div class="pay-box" v-if="show">
        <div class="pay-main">
            <img class="close-icon" @click="closeBox" src="../../static/icon/close-icon.png">
            <div class="main">
                <div class="main-title">
                    <img src="../../static/icon/logo.png">
                    <span>财讯</span>
                </div>
                <div class="login-type">
                    <span class="type-span type-span-active">绑定手机号</span>
                </div>
                <div class="input-box">
                    <img src="../../static/icon/user-icon.png">
                    <input v-model="phoneNumber" type="text" placeholder="请输入手机号">
                </div>
                <div class="input-box">
                    <img src="../../static/icon/psd-icon.png">
                    <input v-model="code" type="text" placeholder="请输入验证码">
                    <span class="get-code" @click="getCode">{{ smsCodeTrue ? '获取验证码' : smsCodeTime + '秒后再次获取' }}</span>
                </div>
                <div class="btn" @click="goBind">
                    <span>立即绑定</span>
                    <img src="../../static/icon/login-icon.png">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { wechatBind, smsCode, getUserInfo } from '@/api/login.js'
import { validatePhoneNumber } from '@/utils/utils.js'
export default {
    name: "bindBox",
    props: {
        show: {
            type: Boolean
        },
        bindCode: {
            type: String
        }
    },
    watch: {
        show(e) {
            this.isShow = e
        }
    },
    data() {
        return {
            isShow: false,
            phoneNumber: '',//手机号
            code: '',//验证码
            smsCodeTrue: true,//是否可以发送验证码
            smsCodeTime: null,//验证码倒计时
            timeDown: null,//验证码倒计时计时器
        }
    },
    created() {
        this.isShow = this.show
    },
    beforeDestroy(){
        this.$store.commit('changeBindCode' , '')
    },
    methods: {
        /**
         * 关闭弹窗
         */
        closeBox() {
            clearInterval(this.timeDown)
            this.$store.commit('changeBindCode' , '')
            this.$emit('close')
        },
        /**
         * 获取验证码
         */
        getCode() {
            if (!this.smsCodeTrue) {
                this.$message.error('请' + this.smsCodeTime + '秒后再次发送');
                return
            }
            if (!validatePhoneNumber(this.phoneNumber)) {
                this.$message.error('请输入正确手机号');
                return
            }
            smsCode({
                phone: this.phoneNumber
            }).then(() => {
                this.$message.success('发送验证码成功')
                this.smsCodeTrue = false
                this.smsCodeTime = 60
                this.timeDown = setInterval(() => {
                    if (this.smsCodeTime !== 0) {
                        this.smsCodeTime--
                    } else {
                        clearInterval(this.timeDown)
                        this.smsCodeTrue = true
                        this.smsCodeTime = null
                    }
                }, 1000);
            })
        },
        goBind() {
            if (!validatePhoneNumber(this.phoneNumber)) {
                this.$message.error('请输入正确手机号');
                return
            }
            if (!this.code) {
                this.$message.error('请输入验证码');
                return
            }
            wechatBind({
                phoneNumber: this.phoneNumber,
                verificationCode: this.code,
                bindCode: this.bindCode
            }).then((res) => {
                this.$store.commit('changeBindCode' , '')
                this.setToken(res);
                this.$message.success('绑定成功');
                setTimeout(() => {
                    getUserInfo().then(userInfo => {
                        this.setStorage('userId', userInfo.id);
                        this.$router.push({ path: "/refresh" });
                    })
                }, 1500);
            })
        }
    }
}
</script>

<style scoped>
.pay-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
}

.pay-main {
    width: 474px;
    height: 464px;
    background: #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-icon {
    position: absolute;
    right: 8px;
    top: 24px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.pay-title {
    margin-top: 30px;
    color: #333333;
    font-size: 16px;
    text-align: center;
}

.main {
    width: 474px;
    height: 464px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-tips {
    color: #636363;
    font-size: 16px;
}

.main-title {
    color: #333333;
    margin-top: 12px;
    font-size: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
}

.main-title img {
    width: 39px;
}

.main-title span {
    color: #646464;
    font-size: 21px;
    margin-left: 8px;
}

.login-type {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 46px;
    margin-top: 30px;
    margin-bottom: 0px;
}

.login-type span {
    font-size: 16px;
    cursor: pointer;
}

.login-type .type-span {
    color: #999999;
}

.login-type .type-span-active {
    color: #DD0722;
}



.input-box {
    margin-top: 28px;
    width: 388px;
    border-radius: 28px;
    height: 56px;
    background-color: #F0F2F5;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.input-box img {
    width: 28px;
    height: 28px;
}

.input-box input {
    margin-left: 10px;
    flex: 1;
    height: 26px;
    border: none;
    background-color: #F0F2F5;
}

.input-box .get-code {
    font-size: 16px;
    color: #4181B8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
}

.remember-psd {
    margin-top: 14px;
    width: 388px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.remember-psd span {
    color: #999999;
    font-size: 14px;
    margin-left: 8px;
    cursor: pointer;
}

.btn {
    width: 388px;
    height: 46px;
    background-color: #DD0722;
    border-radius: 28px;
    margin-top: 50px;
    cursor: pointer;
}

.btn span {
    color: #FFFFFF;
    font-size: 18px;
    margin-right: 6px;
}

.btn img {
    width: 19px;
    height: 19px;
}
</style>
